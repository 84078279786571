html,body {
  height: 100%;
  font-size: 0.8em;
}

#map-container {
  height: 77vh;
}

#map {
  width: 100%;
  height: 100%;
}

#forecast-vintage-container{
  padding-bottom: 1.0em;
}

.obs-too-high {
  color: red;
}

.obs-too-low {
  color: blue;
}

.leaflet-interactive {
  stroke-opacity: 0;
  fill-opacity: 0.7;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}